
import axios from 'axios';
import { SET_ALL_SHIFTS, SET_AVG, SET_TODAY } from '../types/shiftTypes';

export const setAllShifts = (items) => ({ type: SET_ALL_SHIFTS, payload: items});
export const setAvg = (avg) => ({ type: SET_AVG, payload: avg});
export const setToday = (today) => ({ type: SET_TODAY, payload: today});

export const fetchAllShifts = ({userId, month, year, findedUser}) => {
  return async (dispatch) => {
    const jwt = localStorage.getItem('jwt');
    try {
      let response = await axios.get(`${process.env.REACT_APP_API_URL}/api/shift/clean?user=${userId}&month=${month}&year=${year}`, {
        headers: {
          'Authorization': 'Bearer ' + jwt,
        }
      });
      if(response?.data?.days?.length) {
        dispatch(setAllShifts(response.data.days.map((el, i) => ({
          id: i,
          username: findedUser.name,
          day: el.day,
          shifts: el.shifts,
          hours: el.difference
        }))));
      } else {
        dispatch(setAllShifts([]))
      }
      if(response.data.today) {
        dispatch(setToday({
          id: 32,
          username: findedUser.name,
          day: response.data.today.day,
          shifts: response.data.today.shifts,
          hours: response.data.today.difference
        }))
      } else {
        dispatch(setToday(null));
      }
      if(response && response.data && response.data.avg) {
        dispatch(setAvg(response.data.avg));
      } else {
        dispatch(setAvg(0))
      }
      return true
    } catch (error) {
      console.log({ error });
      return false
    }
  }
}
export const createShift = (shift) => {
  return async (dispatch) => {
    const jwt = localStorage.getItem('jwt');
    try {
      let results = await axios.post(`${process.env.REACT_APP_API_URL}/api/shifts`, {data: shift}, {
        headers: { 'Authorization': 'Bearer ' + jwt }
      });
      if(results.status === 200) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.log({ error });
      return false;
    }
  }
}