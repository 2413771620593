import React, { useState, useEffect } from 'react';
import moment from 'moment';
import axios from "axios";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import CollapsibleTable from '../components/ShiftTable';
import Button from '@mui/material/Button';
import { convertDiffrence } from '../utilities/time';
import { useNavigate } from "react-router-dom";
import { fetchAllUsers, login, requestLogout } from '../redux/actions/userActionCreators';
import { useDispatch, useSelector } from 'react-redux';
import { createShift, fetchAllShifts } from '../redux/actions/shiftActionCreators';
import LogoutIcon from '@mui/icons-material/Logout';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import UpdatedPassword from '../components/UpdatedPassword';
import Snackbar from '@mui/material/Snackbar';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

const Shift = () => {
  const users = useSelector(state => state.user.users);
  const shifts = useSelector(state => state.shift.all);
  const today = useSelector(state => state.shift.today);
  const avg = useSelector(state => state.shift.avg);
  const [localUser, setLocalUser] = useState(null);
  const [selectedUser, setSelectedUser] = useState({});
  const [userId, setUserId] = useState('');
  const [year, setYear] = useState('');
  const [month, setMonth] = useState('');
  const [open, setOpen] = useState(false);
  const [openPassword, setOpenPassword] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const currentDate = moment();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleOpenPassword = () => setOpenPassword(true);
  const handleClosePassword = () => setOpenPassword(false);

  const logout = () => {
    dispatch(requestLogout());
    navigate('/login');
  }

  const getShifts = () => {
    let findedUser = users?.length ? users.find(el => el.id === userId) : JSON.parse(localStorage.getItem("user"));
    dispatch(fetchAllShifts({userId, month, year, findedUser})).then(res => {
      if(!res) {
        dispatch(requestLogout());
        navigate('/login');
      } else {
        let localStorageUser = localStorage.getItem("user");
        const jwt = localStorage.getItem("jwt");
        localStorageUser = JSON.parse(localStorageUser);
        dispatch(login({user: localStorageUser, jwt}))
      }
    })
  }

  const generateXlsx = () => {
    axios.get(`${process.env.REACT_APP_API_URL}/api/shift/clean-export?user=${userId}&month=${month}&year=${year}`)
    .then(response => {
      if(response && response.data) {
        const link = document.createElement('a');
        link.href = `${process.env.REACT_APP_API_URL}${response.data.path}`;
        link.setAttribute('download', response.data.name);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    })
    .catch(error => {
      console.log(error);
    });
  }

  const deleteShift = (id) => {
    axios.delete(`${process.env.REACT_APP_API_URL}/api/shifts/${id}`).then(response => {
      getShifts();
    })
    .catch(error => {
      console.log(error);
    });
  }

  useEffect(() => {
    let localStorageUser = localStorage.getItem("user");
    const jwt = localStorage.getItem("jwt");
    if(!jwt) {
      navigate("/login");
    }
    if(localStorageUser) {
      setYear(currentDate.year());
      setMonth(currentDate.month() + 1);
      localStorageUser = JSON.parse(localStorageUser);
      setUserId(localStorageUser.id);
      setLocalUser(localStorageUser);
      if(localStorageUser?.role?.type === "super_admin") {
        dispatch(fetchAllUsers());
      }
    }
  }, []);

  useEffect(() => {
    if(userId && month && year) {
      let findedUser = users?.length ? users.find(el => el.id === userId) : JSON.parse(localStorage.getItem("user"));
      setSelectedUser(findedUser);
      getShifts();
    }
  }, [userId, month, year])

  const addNewShift = ({provider, dateTime, type}) => {
    dispatch(createShift({provider, timestamp: dateTime, user: userId, type})).then(res => {
      if(res) {
        getShifts();
        setOpen(false);
      }
    })
  }

  return (
    <Container maxWidth="xl" style={{ padding: 24 }}>
      <Grid container justifyContent="flex-end" style={{ padding: 24 }}>
          <Button variant="contained" onClick={() => handleOpenPassword()}>
            <LockOpenIcon />
          </Button>
          <Button variant="contained" onClick={() => logout()} style={{ marginLeft: 24 }}>
            <LogoutIcon />
          </Button>
      </Grid>
      <Grid container spacing={2} style={{ padding: 24 }}>
        <Grid item xs={localUser?.role?.type === "super_admin" ? 4 : 6}>
          <FormControl fullWidth>
            <InputLabel id="month-label">Month</InputLabel>
            <Select
              labelId="month-label"
              id="month"
              value={month}
              label="Month"
              onChange={(e) => setMonth(e.target.value)}
            >
              <MenuItem value={1}>January</MenuItem>
              <MenuItem value={2}>February</MenuItem>
              <MenuItem value={3}>March</MenuItem>
              <MenuItem value={4}>April</MenuItem>
              <MenuItem value={5}>May</MenuItem>
              <MenuItem value={6}>June</MenuItem>
              <MenuItem value={7}>July</MenuItem>
              <MenuItem value={8}>August</MenuItem>
              <MenuItem value={9}>September</MenuItem>
              <MenuItem value={10}>October</MenuItem>
              <MenuItem value={11}>November</MenuItem>
              <MenuItem value={12}>December</MenuItem>

            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={localUser?.role?.type === "super_admin" ? 4 : 6}>
          <FormControl fullWidth>
            <InputLabel id="year-label">Year</InputLabel>
            <Select
              labelId="year-label"
              id="year"
              value={year}
              label="Year"
              onChange={(e) => setYear(e.target.value)}
            >
              <MenuItem value={2018}>2018</MenuItem>
              <MenuItem value={2019}>2019</MenuItem>
              <MenuItem value={2020}>2020</MenuItem>
              <MenuItem value={2021}>2021</MenuItem>
              <MenuItem value={2022}>2022</MenuItem>
              <MenuItem value={2023}>2023</MenuItem>
              <MenuItem value={2024}>2024</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        {localUser?.role?.type === "super_admin" && <Grid item xs={localUser?.role?.type === "super_admin" ? 4 : 6}>
          <FormControl fullWidth>
            <InputLabel id="employees-label">Employee</InputLabel>
            <Select
              labelId="employees-label"
              id="employees"
              value={userId}
              label="Employee"
              onChange={(e) => setUserId(e.target.value)}
            >
              {users.map(el => <MenuItem value={el.id}>{el.name}</MenuItem>)}
            </Select>
          </FormControl>
        </Grid>}
      </Grid>
      <Grid container justifyContent="space-between" style={{ padding: 24 }}>
        <Grid item xs={6}>
        {selectedUser && <div>
          <p><strong>Name:</strong> {selectedUser.name}</p>
          <p><strong>Email:</strong> {selectedUser.email} </p>
          <p><strong>Avergae hours:</strong> {convertDiffrence(avg)} </p>
        </div>}
        </Grid>
        <Grid item xs={1} >
          <Grid container justifyContent="flex-end">
            {localUser?.role?.type === "super_admin" && <Button variant="contained" onClick={() => generateXlsx()}>Export</Button>}
          </Grid>
        </Grid>
      </Grid>
      <div style={{ height: 400, width: '100%' }}>
        <CollapsibleTable 
          shifts={shifts} 
          deleteShift={deleteShift} 
          today={today} 
          selectedUser={selectedUser} 
          addNewShift={addNewShift}
          open={open}
          setOpen={setOpen}
          handleOpen={handleOpen}
          handleClose={handleClose}
          localUser={localUser}
        />
      </div>
      <Modal
        open={openPassword}
        onClose={handleClosePassword}
        aria-labelledby="modal-modal-title-password"
        aria-describedby="modal-modal-description-password"
      >
        <Box sx={style}>
          <UpdatedPassword handleClosePassword={handleClosePassword} setOpenSnackbar={setOpenSnackbar}></UpdatedPassword>
        </Box>
      </Modal>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={() => setOpenSnackbar(false)}
        message="Updated Successfully"
      />
    </Container>
  )
}

export default Shift


