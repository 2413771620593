import { SET_ALL_SHIFTS, SET_AVG, SET_TODAY } from '../types/shiftTypes';


const initialState = {
    all: [],
    avg: 0,
    today: null
};

const shiftReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_ALL_SHIFTS:
            return { ...state, all: action.payload };
        case SET_AVG:
            return { ...state, avg: action.payload };
        case SET_TODAY:
            return { ...state, today: action.payload };
        default:
            return state;
    }
}

export default shiftReducer