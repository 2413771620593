import moment from 'moment';

export const convertDiffrence = (totalMinutes) => {
  const duration = moment.duration(totalMinutes, 'seconds');
  const hours = duration.hours();
  const minutes = duration.minutes();
  const seconds = duration.seconds();
  return `${hours} hours and ${minutes} minutes and ${seconds} seconds`
}


export const getDifferenceOfToday = (row) => {
  let difference = row.hours;
  if(row?.shifts?.length % 2 === 1) {
    const getLastShift = new Date(row?.shifts[row?.shifts.length - 1].timestamp);
    const getNow = new Date()
    difference += Math.floor((getNow - getLastShift) / (1000));
  }
  return difference;
}