
import axios from 'axios';
import { LOGIN, LOGOUT, SET_ALL_USERS } from '../types/userTypes';

export const setAllUsers = (items) => ({ type: SET_ALL_USERS, payload: items});
export const login = (user, jwt) => ({ type: LOGIN, payload: { user, jwt }});
export const logout = () => ({ type: LOGOUT });

export const requestLogout = () => {
  return async (dispatch) => {
    localStorage.removeItem("user");
    localStorage.removeItem("jwt");
    dispatch(logout());
  }
}
export const requestLogin = ({identifier, password}) => {
  return async (dispatch) => {
    try {
      let result = await axios.post(`${process.env.REACT_APP_API_URL}/api/auth/local`, {
        identifier,
        password
      });

      if(result?.data?.jwt) {
        const { user, jwt } = result.data;
        let userDetails = await axios.get(`${process.env.REACT_APP_API_URL}/api/users/${user.id}?populate=*`)
        user.role = userDetails.data.role;
        localStorage.setItem("user", JSON.stringify(user));
        localStorage.setItem("jwt", jwt);
        dispatch(login(user, jwt));
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.log({ error });
      return false;
    }
  };
};
export const fetchAllUsers = () => {
  return async (dispatch) => {
    const jwt = localStorage.getItem('jwt');
    try {
      let results = await axios.get(`${process.env.REACT_APP_API_URL}/api/users/?filters[confirmed]=true&populate=*`, {
        headers: {
          'Authorization': 'Bearer ' + jwt,
        }
      });
      dispatch(setAllUsers([...results.data]));
    } catch (error) {
      console.log({ error });
    }
  }
}
export const updatePassword = ({currentPassword, password, passwordConfirmation}) => {
  const jwt = localStorage.getItem("jwt");
  return async (dispatch) => {
    try {
      let result = await axios.post(`${process.env.REACT_APP_API_URL}/api/auth/change-password`, 
      { 
        currentPassword,
        password,
        passwordConfirmation
      }, {
        headers: {
          'Authorization': 'Bearer ' + jwt,
        }
      });
      if(result?.data) {
        const { user, jwt } = result.data;
        localStorage.setItem("user", JSON.stringify(user));
        localStorage.setItem("jwt", jwt);
        dispatch(login(user, jwt));
        return true;
      } else {
        return null;
      }
    } catch (error) {
      return null;
    }
  };
};