import { LOGIN, LOGOUT, SET_ALL_USERS } from '../types/userTypes';

const initialState = {
    isAuth: false,
    user: null,
    users: [],
    jwt: ""
};
const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case LOGIN:
            return { ...state, isAuth: true, user: action.payload.user, jwt: action.payload.jwt };
        case LOGOUT:
            return { ...state, isAuth: false, user: null, jwt: ""};
        case SET_ALL_USERS:
            return { ...state, users: action.payload };
        
        default:
            return state;
    }
}

export default userReducer