import React, { useState } from 'react'
import Typography from '@mui/material/Typography';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { Button } from '@mui/material';

const AddShift = ({selectedUser, addNewShift}) => {
  const [dateTime, setDateTime] = useState(null);
  const [dateTimeError, setDateTimeError] = useState(false);
  const [provider, setProvider] = useState("APPLICATION");
  const [type, setType] = useState("IN");
  
  const createNewShift = () => {
    if(!dateTime) {
      setDateTimeError(true);
      return;
    }
    addNewShift({provider, dateTime: dateTime.$d, type})
  }

  const changeDateTime = (value) => {
    setDateTime(value);
    setDateTimeError(false);
  }

  return (
    <div style={{width: "100%"}}>
      <Typography id="modal-modal-title" variant="h5" component="h2" style={{marginBottom: "20px"}}>
        Add a Shfit
      </Typography>
      {selectedUser && <div>
        <p><strong>Name:</strong> {selectedUser.name}</p>
        <p><strong>Email:</strong> {selectedUser.email} </p>
      </div>}
      <div>
        <p><strong>Shift:</strong> {dateTimeError && <span style={{color: "#ff0000", paddingLeft: "5px"}}>(Required Field)</span>}</p>
        <DateTimePicker value={dateTime} onChange={(newValue) => changeDateTime(newValue)}  />
      </div>
      <div>
        <p><strong>Provider:</strong></p>
        <Select
          labelId="provider-label"
          id="provider"
          value={provider}
          label="Employee"
          onChange={(e) => setProvider(e.target.value)}
          style={{"width": "100%"}}
        >
          <MenuItem value={"APPLICATION"}>Application</MenuItem>
          <MenuItem value={"POINTEUSE"}>Pointeuse</MenuItem>
        </Select>
      </div>
      <div>
        <p><strong>Type:</strong></p>
        <Select
          labelId="type-label"
          id="types"
          value={type}
          label="type"
          onChange={(e) => setType(e.target.value)}
          style={{"width": "100%"}}
        >
          <MenuItem value={"IN"}>IN</MenuItem>
          <MenuItem value={"OUT"}>OUT</MenuItem>
        </Select>
      </div>
      <Button variant="contained" onClick={() => createNewShift()} fullWidth style={{margin: "20px 0"}}>Add Shift</Button>
    </div>
  )
}

export default AddShift;