import React, { useState } from 'react'
import { TextField, Button, Typography, Alert } from '@mui/material';
import { updatePassword } from '../redux/actions/userActionCreators';
import { useDispatch } from 'react-redux';

const UpdatedPassword = ({handleClosePassword, setOpenSnackbar}) => {

  const [currentPassword, setCurrentPassword] = useState("");
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const dispatch = useDispatch();

  const changePassword = () => {
    let hasErr = false;
    if(!password) {
      hasErr = true;
      setPasswordError('Required field');
    }
    if(!passwordConfirmation) {
      hasErr = true;
      setPasswordError('Required field');
    }
    if(passwordConfirmation?.length < 8) {
      hasErr = true;
      setPasswordError('Short Password');
    }
    if(password !== passwordConfirmation) {
      hasErr = true;
      setPasswordError('DifferentPassword');
    }

    if(!hasErr) {
      dispatch(updatePassword({currentPassword, password, passwordConfirmation}))
      .then(res => {
        if(res) {
          setOpenSnackbar(true);
          handleClosePassword();
        } else {
          setPasswordError("Updated Failed");
        }
      })
    }
  }
  return (
    <div>
      <Typography variant="h5" component="h1" style={{ marginBottom: '20px' }}>
        Update Password
      </Typography>
      <TextField
        variant="outlined"
        margin="normal"
        required
        fullWidth
        name="current-password"
        label="Current Password"
        type="password"
        id="current-password"
        autoComplete="current-password"
        value={currentPassword}
        onChange={(e) => {setPasswordError(""); setCurrentPassword(e.target.value)}}
        style={{ marginBottom: '20px' }}
      />
      <TextField
        variant="outlined"
        margin="normal"
        required
        fullWidth
        name="password"
        label="Password"
        type="password"
        id="password"
        autoComplete="password"
        value={password}
        onChange={(e) => {setPasswordError(""); setPassword(e.target.value)}}
        style={{ marginBottom: '20px' }}
      />
      <TextField
        variant="outlined"
        margin="normal"
        required
        fullWidth
        name="password-confirmation"
        label="Password Confirmation"
        type="password"
        id="password-confirmation"
        autoComplete="Password Confirmation"
        value={passwordConfirmation}
        onChange={(e) => {setPasswordError(""); setPasswordConfirmation(e.target.value)}}
        style={{ marginBottom: '20px' }}
      />
      {passwordError && <Alert severity="error" style={{ marginBottom: '20px' }}>{passwordError}</Alert>}
      <Button
        fullWidth
        variant="contained"
        color="primary"
        onClick={changePassword}
      >
        Update Password
      </Button>
    </div>
  )
}

export default UpdatedPassword;