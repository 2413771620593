import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import moment from 'moment';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import { convertDiffrence, getDifferenceOfToday } from '../utilities/time';
import Tooltip from '@mui/material/Tooltip';
import DeleteIcon from '@mui/icons-material/Delete';
import AddShift from './AddShift';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};


function Row(props) {
  const { row , deleteShift, isToday } = props;
  const [difference, setDifference] = useState(0);
  const [open, setOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [selectedShift, setSelectedShift] = useState(false);
  const handleOpenModal = () => setOpenModal(true);
  const handleClose = () => setOpenModal(false);
  const [intervalDiff, setIntervalDiff] = useState(null);

  const removeShift = () => {
    deleteShift(selectedShift.id);
    handleClose();
  }

  useEffect(() => {
    if(!isToday || (isToday && row?.shifts?.length % 2 === 0)) {
      setDifference(row.hours);
      if(intervalDiff) {
        clearInterval(intervalDiff)
      }
    } else {
      setIntervalDiff(setInterval(() => {
        setDifference(getDifferenceOfToday(row));
      }, 1000));
    }
  }, [row])

  return (
    <>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell align="center">
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
        </TableCell>
        <TableCell align="center" component="th" scope="row">
          {row.username}
        </TableCell>
        <TableCell align="center">{row.day}</TableCell>
        <TableCell align="center">
          {convertDiffrence(difference)} 
           {row?.shifts?.length % 2 === 1 && !isToday && <Tooltip title="There is a Problem">
           <Button variant="contained" color="error" style={{ marginLeft: 12, minWidth: 0, width: '32px', height: '32px', borderRadius: '50%', padding: 0}}>!</Button>
            </Tooltip>}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={3}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 4 }}>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell><strong>Time</strong></TableCell>
                    <TableCell><strong>type</strong></TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.shifts?.length ? row.shifts.map((shift) => (
                    <TableRow key={shift.id}>
                      <TableCell component="th" scope="row">
                        {moment(shift.timestamp).format('HH:mm')}
                      </TableCell>
                      <TableCell>{shift.type}</TableCell>
                      <TableCell onClick={handleOpenModal}>
                      <div style={{cursor: "pointer", color: "red"}} onClick={() => setSelectedShift(shift)}><strong>X</strong> </div> 
                      </TableCell>
                    </TableRow>
                  )) : ""}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
      <Modal
        open={openModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Delete shift
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Are you sure you want to delete this shift.
          </Typography>
          <div container spacing={2} style={{marginTop: 20, display: "flex", justifyContent: "center", width: "100%", gap: 12}}>
            <Button variant="outlined" color="error" onClick={removeShift}>
              Delete
            </Button>
            <Button variant="contained" onClick={handleClose}>
              Cancel
            </Button>
          </div>
        </Box>
      </Modal>
    </>
  );
}

export default function CollapsibleTable({localUser, shifts, deleteShift, today, selectedUser, addNewShift, open, setOpen, handleOpen, handleClose}) {
  
  return (
    <>
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
            <TableCell align="center">
             {localUser?.role?.type === "super_admin" && <Button variant="contained" color="primary" style={{ marginLeft: 12, minWidth: 0, width: '32px', height: '32px', borderRadius: '50%', padding: 0}} onClick={handleOpen}>+</Button>}
            </TableCell>
              <TableCell align="center">Employee</TableCell>
              <TableCell align="center">Day</TableCell>
              <TableCell align="center">Hours</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {today && <Row key={today.id} row={today} deleteShift={deleteShift} isToday={true}/>}
            {shifts.map((row) => (
              <Row key={row.id} row={row} deleteShift={deleteShift}/>
            ))}
          </TableBody>
        </Table>
        
      </TableContainer>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <AddShift selectedUser={selectedUser} addNewShift={addNewShift}></AddShift>        
        </Box>
      </Modal>
    </>

  );
}