import React, { useEffect, useState } from 'react';
import { Container, TextField, Button, Typography, Paper, Grid } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { requestLogin } from '../redux/actions/userActionCreators';
import { useNavigate } from "react-router-dom";

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const dispatch = useDispatch()
  const [errorMsg, setErrorMsg] = useState('');
  const navigate = useNavigate();

  const paperStyle = {
    padding: 20,
    margin: '20px auto',
  };

  const btnStyle = {
    margin: '20px 0',
  };

  const handleEmailChange = (event) => {
    setErrorMsg('');

    setEmail(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setErrorMsg('');
    setPassword(event.target.value);
  };

  const handleClick = () => {
    dispatch(requestLogin({identifier: email, password})).then(res => {
      if(res) {
        setErrorMsg('');
        navigate("/shift");
      } else {
        setErrorMsg('Invalid email or password');
      }
    })
  };


  useEffect(() => {
    const jwt = localStorage.getItem("jwt");
    if(jwt) {
      navigate("/shift");
    }
  }, [])

  return (
    <Container component="main" maxWidth="xs">
      <Grid container justifyContent="center" alignItems="center" style={{ height: '100vh' }}>
        <Paper elevation={3} style={paperStyle}>
          <Typography variant="h5" component="h1" style={{ marginBottom: '20px' }}>
            Login
          </Typography>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            value={email}
            onChange={handleEmailChange}
            style={{ marginBottom: '20px' }}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            value={password}
            onChange={handlePasswordChange}
            style={{ marginBottom: '20px' }}
          />
          {errorMsg && <Typography variant="body2" color="error">{errorMsg}</Typography>}
          <Button
            fullWidth
            variant="contained"
            color="primary"
            style={btnStyle}
            onClick={handleClick}
          >
            Sign In
          </Button>
        </Paper>
      </Grid>
    </Container>
  );
};

export default Login;
